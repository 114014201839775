import * as React from "react"
import { Helmet } from 'react-helmet'
import styled, {createGlobalStyle} from 'styled-components'
import {theme} from '../theme'
import { graphql } from 'gatsby'
import MultiArrow from '../components/multi-arrow'
import Logo from '../components/tall-talos'
import Seo from '../components/seo'

const GloblaStyle = createGlobalStyle`
  html {
    box-sizing: border-box;
  }
  *, *:before, *:after {
    box-sizing: inherit;
  }

  body {
    margin: 0;
    padding: 0;
    color: ${theme.color.sea};
    background-color: ${theme.color.dark};
    font-family: 'Rubik', sans-serif;
  }
`

// markup
const IndexPage = ({data}) => {
  // console.log(data)
  const content = data.allFile.edges[0].node.childMarkdownRemark.rawMarkdownBody
  const page = data.allFile.edges[0].node.childMarkdownRemark.frontmatter

  const meta = {
    title: page.title,
    description: content,
    image: "/img/seo-image-2.png"
  }
  
  return (
    <Main>
      <GloblaStyle />
      <Seo meta={meta} />
      <Helmet>
        <title>{page.title}</title>
        <link rel="preconnect" href="https://fonts.gstatic.com" /> 
        <link href="https://fonts.googleapis.com/css2?family=Rubik:wght@300..900&display=swap" rel="stylesheet" />
      </Helmet>
        <div>
          <ShowOnDesktop><img src="/img/big-rune-fish.svg" className="bigfish" alt=""/></ShowOnDesktop>
          <ShowOnMobile><img src="/img/big-rune-fish-mobile.svg" className="bigfish" alt=""/></ShowOnMobile>
        </div>
      <Body>
        <TalosLogo logoColor="white" noFish />
        <p>{content}</p>
        <a href={page.contact_link}>{page.contact_text} <MultiArrow /></a>
      </Body>
    </Main>
  )
}

export default IndexPage

const TalosLogo = styled(Logo)` 
`

const Main = styled.main`
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  display: flex;
  flex-direction: column;
  @media (min-width: 960px) {
    flex-direction: row;
    align-items: flex-end;
  }
  & > div:first-child {
    position: relative;
    flex: 1 1 80%;
    @media (min-width: 960px) {
      height: 100%;
    }
    & img {
      position: absolute;
      width: 100%;
      height: 100%;
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;
    }
  }
`

const Body = styled.div`
  position: relative;
  flex: none;
  display: flex;
  flex-direction: column;
  padding: 5vmin 5vmin 10vmin 5vmin;
  max-width: calc(480px + 2.5vw);
  font-size: calc(1em + 0.5vw);
  & ${TalosLogo} {
    width: 270px;
    max-width: 40vmin;
  }
  & p {
    margin: 2em 0 1em;
    color: white;
  }
  & a {
    color: ${theme.color.verbillion};
    text-decoration: none;
    &:hover {
      color: ${theme.color.verbillion};
    }
  }
`

const ShowOnDesktop = styled.div`
  display: none;
  @media (min-width: 960px) {
    display: block;
  }
`

const ShowOnMobile = styled.div`
  display: block;
  @media (min-width: 960px) {
    display: none;
  }
`

export const query = graphql`
  query HomePageQuery2a {
    allFile(filter: {name: {eq: "home"}, sourceInstanceName: {eq: "data"}}) {
      edges {
        node {
          childMarkdownRemark {
            rawMarkdownBody
            frontmatter {
              contact_link
              contact_text
              title
            }
          }
        }
      }
    }
  }
`